<template>
  <loading-spinner v-if="state.loading" />
  <div v-else>
    <the-header-card @refresh="loadPageInfo(true)">
      <va-button class="ml-2" @click="generateRedeemLink()"  v-if="canRedeemToken">
        {{ $t("buttons.redeem") }}
      </va-button>
      <va-button v-if="state.editMode" flat class="ml-2" @click="cancelUpdate()">
        {{ $t("buttons.cancel") }}
      </va-button>
      <va-button v-if="state.editMode" class="ml-2" @click="saveEntitlement()">
        {{ $t("buttons.save") }}
      </va-button>
      <va-button v-else-if="state.canEdit" class="ml-2" @click="state.editMode = true">
        {{ $t("buttons.edit") }}
      </va-button>
    </the-header-card>

    <div class="row row-equal" v-if="state.entitlement && Object.keys(state.entitlement).length">
      <div class="flex flex-grow-1 xl16">
        <entitlement-information-tile :editMode="state.editMode" />
      </div>
      <div class="flex flex-grow-1 xl16">
        <entitlement-attributes-tile  :editMode="state.editMode" />
      </div>
      <div class="flex flex-grow-1 xl16">
        <entitlement-usage-tile :editMode="state.editMode" />
      </div>
    </div>
    <va-button-toggle outline class="mb-3" v-model="state.currentLicenseeSwitch" :options="SWITCH_OPTIONS" />
    <assignments-table v-if="state.currentLicenseeSwitch === 'assignment'" :entitlement-id="entitlementId" />
    <activations-table v-if="state.currentLicenseeSwitch === 'activation'" :entitlement-id="entitlementId" />
  </div>
  <redeem-token-modal
    v-if="state.showHideRedeemModal"
    :isOpen="state.showHideRedeemModal"
    :entitlementId="entitlementId"
    @cancel="closeRedeemModal"
  />
</template>

<script setup>
import { inject, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import EntitlementInformationTile from "@/components/entitlements/EntitlementInformationTile.vue";
import EntitlementUsageTile from "@/components/entitlements/EntitlementUsageTile.vue";
import EntitlementAttributesTile from "@/components/entitlements/EntitlementAttributesTile.vue";
import TheHeaderCard from "@/components/TheHeaderCard.vue";
import ActivationsTable from "@/components/activations/ActivationsTable.vue";
import AssignmentsTable from "@/components/assignments/AssignmentsTable.vue";
import RedeemTokenModal from "@/components/modal/RedeemTokenModal.vue";

const route = useRoute();
const handleError = inject("handleError");
const store = useStore();
const { t } = useI18n();
const toasts = inject("toasts");
const entitlementId = computed(() => route.params.id);

const canUserEdit = () => {
  const hasGlobalAccess = store.state.auth.hasGlobalAccess;
  const permissions = store.state.auth.permissions;
  return hasGlobalAccess || permissions.includes('update:entitlements');
};

const SWITCH_OPTIONS = [
  { label: t("activations.title"), value: "activation" },
  { label: t("assignments.title"), value: "assignment" }
];

const state = reactive({
  loading: false,
  entitlement: computed(() => store.state.licensing.entitlementEdit),
  canEdit: canUserEdit(),
  editMode: false,
  licensesTableKey: 1,
  currentLicenseeSwitch: "activation",
  showHideRedeemModal: false,
});

const loadEntitlementInfo = async () => {
  try {
    await store.dispatch("licensing/setEntitlement", route.params.id);
  } catch (err) {
    handleError(err?.response?.data);
  }
};

const canRedeemToken = computed(() => {
  const permissions = store.state.auth.permissions;
  const hasGlobalAccess = store.state.auth.hasGlobalAccess;
  return hasGlobalAccess || permissions.includes('read:entitlement_redeem_token');
});

const loadOwnerInfo = async () => {
  const ownerEntitlement = store.state.licensing.entitlement.owner;

  if (ownerEntitlement) {
    if (ownerEntitlement?.type === "User") {
      await store.dispatch("identity/fetchUser", ownerEntitlement.id);
    } else if (ownerEntitlement?.type === "Invite") {
      await store.dispatch("identity/fetchInvite", ownerEntitlement.id);
    } else if (ownerEntitlement?.type === "Organization") {
      await store.dispatch("identity/fetchOrganization", ownerEntitlement.id);
    } else {
      toasts?.error(t("toast.error_owner_type"));
    }
  }
};

const buildEntitlementPayload = () => {
  const entitlement = store.state.licensing.entitlement;
  const entitlementEdit = store.state.licensing.entitlementEdit;
  
  console.log('Entitlement Edit before saving:', entitlementEdit); // Check the latest state
  console.log('Entitlement before saving:', entitlement); // Check the latest state

  return {
    attributes: entitlementEdit.attributes.map(attribute => ({
      name: attribute.name,
      value: attribute.value  // Ensure we're using the correct `value` here
    })),
    ...(entitlementEdit.expiresAt !== entitlement.expiresAt && {
      expiresAt: entitlementEdit.expiresAt
    }),
    ...(entitlementEdit.maintenanceEndsAt !== entitlement.maintenanceEndsAt && {
      maintenanceEndsAt: entitlementEdit.maintenanceEndsAt
    }),
    ...(entitlementEdit.isTrial !== entitlement.isTrial && {
      isTrial: entitlementEdit.isTrial
    }),
    ...(entitlementEdit.suspended !== entitlement.suspended && {
      suspended: entitlementEdit.suspended
    }),
    ...(entitlementEdit.revoked !== entitlement.revoked && {
      revoked: entitlementEdit.revoked
    }),
    ...(entitlementEdit.subscriptionId !== entitlement.subscriptionId &&
      [undefined, ""].indexOf(entitlementEdit.subscriptionId) === -1 && {
      subscriptionId: entitlementEdit.subscriptionId
    }),
    ...(entitlementEdit.origin !== entitlement.origin && {
      origin: entitlementEdit.origin
    }),
    ...((entitlementEdit.ownerId !== entitlement.owner?.id || entitlementEdit.ownerId === null) && {
      ownerId: entitlementEdit.ownerId,
    }),
    ...(entitlementEdit.seats !== entitlement.seats &&
      Number.isInteger(entitlementEdit.seats) &&
      entitlementEdit.seats <= 2147483647 &&
      [undefined, 0].indexOf(entitlementEdit.seats) === -1 && {
      seats: entitlementEdit.seats
    }),
    ...(entitlementEdit.templateId && { templateId: entitlementEdit.templateId }) // Include templateId if available
  };
};



const generateRedeemLink = async () => {
  state.showHideRedeemModal = true;
}
const closeRedeemModal = () => {
  state.showHideRedeemModal = false;
};
const saveEntitlement = async () => {
  const entitlementId = route.params.id;
  const payload = buildEntitlementPayload();
  await store.dispatch("licensing/patchEntitlement", {
    entitlementId,
    payload
  });


  await loadOwnerInfo();
  state.editMode = false;
  toasts?.info(t("entitlements.entitlementUpdateSucess"));
};

const cancelUpdate = () => {
  state.editMode = false;
  store.dispatch("licensing/resetEntitlementEdit");
};

const updateTableKey = () => {
  state.licensesTableKey = 3 - state.licensesTableKey;
};

const loadPageInfo = async (refreshTables = false) => {
  state.loading = true;
  await loadEntitlementInfo();
  await loadOwnerInfo();

  if (refreshTables) {
    updateTableKey();
  }
  state.loading = false;
};

onMounted(async () => {
  await loadPageInfo();
});
</script>

<style scoped>
.flex-grow-1 {
  flex-grow: 1;
}
</style>
