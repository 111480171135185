<template>
  <va-card class="card-container">
    <va-card-title>
      <slot name="title">
        {{ $t("assignments.title") }}
      </slot>
    </va-card-title>
    <va-card-content>
      <va-data-table
        :no-data-html="$t('assignments.noItems')"
        :items="state.assignments"
        :columns="columns">

        <template #header(assignee)>{{ $t("assignments.assignee") }}</template>
        <template #cell(assignee)="{ source: assignee }">
          <span class="badges">
            <router-link :to="{ path: identityLink(assignee) }">
              {{ assignee.email ?? assignee.name }}
            </router-link>

            <va-badge
              v-if="assignee.type === 'Invite'"
              :text="$t('assignments.invite')"
              color="#8093F1"/>

            <va-badge
              v-else-if="assignee.type === 'User'"
              :text="$t('assignments.user')"
              color="#B3B3A8"/>

            <button-clipboard-copy :field="$t('assignments.email')" :value="assignee.email ?? assignee.name" />
          </span>
        </template>

        <template #header(createdAt)>{{ $t("assignments.createdAt") }}</template>
        <template #cell(createdAt)="{ source: createdAt }">
          {{ printDate(createdAt) }}
        </template>

        <template #header(actions)>{{ $t("assignments.actions") }}</template>
        <template #cell(actions)="{ rowIndex }">
          <va-button
            color="#DC2626"
            flat
            icon="delete"
            @click="openUnassignModal(rowIndex)"
          />
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination
        class="pagination"
        input
        color="#000000"
        v-model="state.currentPage"
        :hide-on-single-page="true"
        :pages="state.totalPages"
        :page-size="state.perPage"
      />
    </va-card-content>
  </va-card>
</template>

<style scoped>
.badges {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>

<script setup>
import {  reactive, watch, onMounted, inject } from "vue";
import { callFetchAssignments, callDeleteAssignmen } from "@/api/licensing";
import { useI18n } from "vue-i18n";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { printDate } from "@/utils";
import { identityLink } from "@/utils/identity";

const { t } = useI18n();
const toasts = inject("toasts");
const handleError = inject("handleError");
const props = defineProps({
  entitlementId: String
});

const columns = ["assignee", "createdAt", "actions"];

const state = reactive({
  assignments: [],
  loading: false,
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0
});

async function loadAssignments(page = 1) {
  state.loading = true;

  const params = {
    count: state.perPage,
    page,
    entitlementId: props.entitlementId
  };

  try {
    const resp = await callFetchAssignments({ params });

    if (resp?.data) {
      const assignments = [];

      for (const item of resp.data.items) {
        assignments.push({
          id: item.id,
          assignee: item.assignee,
          createdAt: item.createdAt,
        })
      }

      state.assignments = assignments;

      updatePagination(resp.data.total, page);
    }
  } catch (e) {
    handleError(e?.response?.data);
  }

  state.loading = false;
}

function updatePagination(totalItems, page = 1) {
  state.totalItems = totalItems;
  state.currentPage = page;
  state.totalPages =
    Math.floor(totalItems / state.perPage) +
    (totalItems % state.perPage === 0 ? 0 : 1);
}

const openUnassignModal = rowIndex => {
  state.unassigngId = state.assignments[rowIndex].id;
  unassignItem();
};

const unassignItem = async () => {
  try {
    await callDeleteAssignmen(state.unassigngId);
    const itemIndex = state.assignments.findIndex(
      el => el.id === state.unassigngId
    );
    state.assignments.splice(itemIndex, 1);
    toasts?.info(t("assignments.unassignSuccess"));
  } catch (err) {
    toasts?.error(err);
  }
  state.unassigngId = "";
};

watch(
  () => state.currentPage,
  async currentPage => {
    await loadAssignments(currentPage);
  }
);

onMounted(async () => {
  await loadAssignments();
});
</script>

<style>
.badge {
  display: inline-flex;
  align-items: center;
  background-color: #DC2626;
  color: white;
  padding: 0.5em;
  border-radius: 12px;
  cursor: pointer;
}
.badge-content {
  margin-right: 0.5em;
}
.icon-delete {
  font-size: 16px;
}
</style>
