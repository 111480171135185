<template>
  <va-card class="card-container">
    <va-card-title>
      <slot name="title">
        {{ $t("activations.title") }}
      </slot>
      <va-button flat class="upgrade-button" @click="openEventsLogs()">
        {{ $t("buttons.showLogs") }}
      </va-button>
    </va-card-title>
    <va-card-content>
      <va-data-table
        :no-data-html="$t('activations.noItems')"
        :items="state.activations"
        :columns="state.columns"
      >
        <template #cell(createdAt)="{source: createdAt}">
          {{  createdAt ? printDate(createdAt) : "Never"  }}
        </template>
        <template #header(lastSynchronizarion)>
          {{ $t("activations.lastSync") }}
        </template>
       
        <template #cell(user)="{ source: user }">
          <template v-if="user">
            {{ user }}
          </template>
          <span v-else>--</span> 
        </template>

        <template #cell(lastSynchronizarion)="{ source: lastSynchronizarion }">
          {{ printDate(lastSynchronizarion) }}
        </template>
        <template #cell(deactivate)="{ rowIndex }">
          <va-button
            color="#DC2626"
            flat
            icon="delete"
            @click="openDeactivationModal(rowIndex)"
          />
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination class="pagination" input color="#000000" v-model="state.currentPage"
        :hide-on-single-page="true" :pages="state.totalPages" :page-size="state.perPage" />
    </va-card-content>
  </va-card>
  <show-activations-events-modal id="eeee"
    :isOpen="state.showActivationsEventsModalIsOpen"
    @cancel="state.showActivationsEventsModalIsOpen = false"
    @validate="validate()"
  />
</template>
<style scoped>
.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  flex: 1; /* Ensures title takes up all available space */
}

.upgrade-button {
  margin-left: auto; /* Pushes the button to the right */
}
</style>
<script setup>
import { computed, reactive, watch, onMounted, inject } from "vue";
import { callFetchActivations, callDeleteActivation } from "@/api/licensing";
import { printDate } from "@/utils";
import { useI18n } from "vue-i18n";
import ShowActivationsEventsModal from "@/components/modal/ShowActivationsEventsModal.vue";


const { t } = useI18n();
const toasts = inject("toasts") ;
const handleError = inject("handleError");
const props = defineProps({
    entitlementId: String
});

const state = reactive({
    activations: [],
    columns: computed(() => {
        if (state.activations[0] == null) {
            return [];
        }
        const columns = Object.keys(state.activations[0]).map(key => {
            return { key };
        });
        columns.push({ key: "deactivate" });
        return columns;
    }),
    loading: false,
    currentPage: 1,
    totalItems: 0,
    perPage: 10,
    totalPages: 0,
    deactivatingId: "",
    showDeactivationModal: computed(() => !!state.deactivatingId),
    showActivationsEventsModalIsOpen: false

});

const openEventsLogs = () => {
  state.showActivationsEventsModalIsOpen = true
}
const validate = () => {
  state.showActivationsEventsModalIsOpen = false;
};
async function loadActivations(page = 1) {
  state.loading = true;

  const params = {
    count: state.perPage,
    page,
    // licenseId: route.params.id,
    entitlementId: props.entitlementId
  };

  try {
    const resp = await callFetchActivations({ params });
 //console.log(resp)
    if (resp?.data) {
      state.activations = resp.data.items.map(item => {
        return {
          createdAt: item?.createdAt,
          lastSynchronizarion: item?.lastRefreshedAt,
          os: item?.os,
          osVersion: item?.osVersion,
          hostName: item?.hostName,
          user: item?.user?.name,
          id: item?.id
   
        };
      });
      state.totalItems = resp.data.total;
      state.totalPages =
        Math.floor(resp.data.total / state.perPage) +
        (resp.data.total % state.perPage === 0 ? 0 : 1);
      state.currentPage = page;
    }
  } catch (e) {
    handleError(e?.response?.data);
  }

  state.loading = false;
}

const openDeactivationModal = rowIndex => {
  state.deactivatingId = state.activations[rowIndex].id;
  deactivateItem()

};

const deactivateItem = async () => {

  try {
    await callDeleteActivation(state.deactivatingId);

    // remove it from the state
    const itemIndex = state.activations.findIndex( 
      el => el.id === state.deactivatingId
    );
    state.activations.splice(itemIndex, 1);
    toasts?.info(t("activations.deactivateSuccess"));
  } catch (err) {
    toasts?.error(err);
  }
  state.deactivatingId = "";
state.deactivatingId = "";
};

watch(
    () => state.currentPage,
    async currentPage => {
        await loadActivations(currentPage);
    }
);

onMounted(async () => {
    await loadActivations();
});

</script>